import { policiesService } from "@/services";

export const templateMixin = {
  data() {
    return {
      merchant: null,
      loadingMerchants: false,
      payMerchants: null,
      loadingPayMerchants: false,
    };
  },
  computed: {
    merchantName() {
      if (this.merchant) {
        return this.merchant["unique_name"].split(":")[1];
      } else {
        const arr = this.$store.state.users.merchant.split(":");
        return arr[arr.length - 1];
      }
    },
    merchantUniqueName() {
      if (this.merchant) {
        return this.merchant["unique_name"];
      } else {
        return this.$store.state.users.merchant;
      }
    },
  },
  methods: {
    fetchMerchants() {
      this.loadingMerchants = true;
      return policiesService
        .fetchUserMerchants(this.companyName)
        .then((res) => {
          if (res.length > 0) {
            // Only one merchant is in the array according to Antonio
            this.merchant = res[0];
          } else {
            console.warn("More than one or zero merchants returned!", res);
          }
          return res;
        })
        .finally(() => (this.loadingMerchants = false));
    },
    fetchPayMerchants() {
      this.loadingPayMerchants = true;
      return policiesService
        .getRemoteMerchants(this.companyName)
        .then((res) => {
          if (res.length > 0) {
            this.payMerchants = res;
          } else {
            console.warn("No pay merchants found!", res);
          }
          return res;
        })
        .finally(() => {
          this.loadingPayMerchants = false;
        });
    },
  },
};
