export const commonMixin = {
  computed: {
    className: function () {
      if (this.$route.query.class_name) {
        return this.$route.query.class_name;
      } else if (this.classId) {
        this.$store.getters["menu/getClassNameById"](
          this.classId,
          this.companyName
        );
      } else {
        return null;
      }
    },
    companyName: function () {
      if (this.$route.params.company) {
        return this.$route.params.company;
      } else {
        return null;
      }
    },
  },
};
